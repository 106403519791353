.navbar__container {
    margin-top: 45px;
    /* background: aqua; */
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@media (max-width : 1036px) {
    .navbar__container{
        display: flex;
        flex-direction: column;
        align-items: start;
    }
}
.title__content{
    font-family: "Poppins";
    font-weight: 500;
    font-size: 26px;
    line-height: 39px;
    margin: 0;
    color:rgba(0, 0, 0, 1)
}

.button__container {
    display: flex;
    gap: 14px;
}


@media (max-width : 1036px) {
    .navbar__container{
        display: flex;
        flex-direction: column;
        align-items: start;
    }
    .button__container{
        margin-top: 10px;
    }
    .two__container{
        flex-direction: column;
    }
}

.button__content{
    color: rgba(255, 255, 255, 1);
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    font-weight: 400;
}

.title__container{
    margin: 0;
    padding: 0;
    margin-top: 27px;
    /* background: teal; */
}

.title__container__data{
    margin: 0;
    font-size: 28px;
    line-height: 27px;
    color: rgba(94, 94, 94, 1);
    font-family: 'Poppins';
    font-weight: 500;
}

.two__container {
    margin-top: 14px;
    display: flex;
    width: 100%;
    gap: 45px;

}
.left__container {
    background: rgba(255, 255, 255, 1);
    width: 100%;
    /* max-width: 620px; */
    border-radius: 17px;
    max-height: 329px;
  

}

.left__container__box{
    padding: 32px 34px;
}



.name__container {
    display: grid;
    grid-template-columns: 35% 65%;
    gap: 1rem;
    /* display: flex ; */
    /* gap: 105px; */
    /* justify-content:space-between   ; */
}

.left__side__fields {
    margin: 0;
    font-family: "Poppins";
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: rgba(94, 94, 94, 1);
}
.right__side__fields__data{
    margin: 0;
    font-family: "Poppins";
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: rgba(0, 0, 0, 1);
}

.line__container {
    border: 1px solid rgba(229, 229, 229, 1);
    margin-top: 12px;
}

.drop__name__container{
margin-top: 9px;
}

.email__styles__container {
    display: flex;
    gap: 0.9rem;
    color: rgba(220, 115, 76, 1);
    align-items: center;
    transition: color 0.3s ease; 
    
}

.email__styles__container:hover {
    cursor: pointer;
}

.right__container {
    border-radius: 17px;
    width: 100%;
    border: 1px solid rgba(172, 172, 172, 1);
    overflow: hidden;
    position: relative;
}
.right__container__box{
    padding: 32px 34px;
  
}

@media (max-width : 800px) {
    .two__container {
        margin-top: 14px;
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 45px;
    
    }
    .name__container {
        display: grid;
        grid-template-columns: 35% 65%;
        /* display: flex ; */
        /* gap: 105px; */
        /* justify-content:space-between   ; */
    }
}